import * as windowSize from "./window_size"

type ImageNodes = NodeListOf<HTMLElement> | Array<HTMLElement>

const ATTRIBUTES =
  {
    smallPalm: ["imgSmallPalm", "imgMobile", "imgHandheld", "imgPortable"],
    palm: ["imgPalm", "imgPalmAndUp", "imgMobile", "imgHandheld", "imgPortable"],
    tab: ["imgPalmAndUp", "imgTab", "imgTabAndUp", "imgHandheld", "imgPortable"],
    lap: ["imgPalmAndUp", "imgTabAndUp", "imgLap", "imgLapAndUp", "imgPortable"],
    desk: ["imgPalmAndUp", "imgTabAndUp", "imgLapAndUp"]
  }

const IMAGE_SELECTOR = '.js-img, [data-img]'
const FALLBACK_ATTR = 'img'

const isHidden = (img: HTMLElement): boolean =>
  img.getClientRects().length === 0 || img.offsetParent === null

const getURL = (img: HTMLElement) : string | undefined => {
  let attributes : Array<string>
  if (windowSize.isSmallPalm()) {
    attributes = ATTRIBUTES.smallPalm
  } else if (windowSize.isPalm()) {
    attributes = ATTRIBUTES.palm
  } else if (windowSize.isTab()) {
    attributes = ATTRIBUTES.tab
  } else if (windowSize.isLap()) {
    attributes = ATTRIBUTES.lap
  } else {
    attributes = ATTRIBUTES.desk
  }

  for (const attr of attributes.reverse()) {
    const url = img.dataset[attr]
    if (url) {
      return url
    }
  }
  return img.dataset[FALLBACK_ATTR]
}

const processImages = (processor : () => void) : void => {
  processor()
  window.addEventListener('resize', processor)
  window.addEventListener('orientationchange', processor)
}

const setBackgroundImages = (nodes: ImageNodes): void => {
  const onLoad = (node: HTMLElement, url: string) => (): void => {
    node.style.backgroundImage = `url(${url})`
  }

  nodes.forEach((node) => {
    if (isHidden(node) || node.querySelector("img")) return

    const url = getURL(node)
    if (url) {
      const img = new Image()
      img.onload = onLoad(node, url)
      img.src = url
    }
  })
}

const appendPictureTags = (nodes: ImageNodes): void => {
  for (const node of [...nodes]) {
    const picture = node.querySelector("picture")

    // Don't load if picture already exists or node is hidden
    if (picture || isHidden(node)) {
      continue
    }

    const { dataset } = node
    const { imgAlt, imgFetch, imgHeight, imgLoading, imgSrc, imgWidth, srcsizes } = dataset
    const srcset2x = dataset["srcset-2x"]
    const srcset1x = dataset["srcset-1x"]

    node.insertAdjacentHTML("beforeend", `
      <picture>
        ${srcset2x?.split(";").map((srcset) => `
          <source
            media="(-webkit-min-device-pixel-ratio: 1.5)"
            srcset="${srcset}"
            sizes="${srcsizes}"
          >
        `).join("")}
        ${srcset1x?.split(";").map((srcset) => `<source srcset="${srcset}">`).join("")}
        <img
          src="${imgSrc}"
          width="${imgWidth}"
          height="${imgHeight}"
          alt="${imgAlt}"
          fetchpriority="${imgFetch || "auto"}"
          loading="${imgLoading || "lazy"}"
        >
      </picture>
    `)
  }
}

const defaultNodes = (): NodeListOf<HTMLElement> =>
  document.querySelectorAll<HTMLElement>(IMAGE_SELECTOR)

const loadBackgroundImages = (nodes: ImageNodes = defaultNodes()): void => {
  const intersectionObserver = new window.IntersectionObserver(
    (entries, observer) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          processImages(setBackgroundImages.bind(null, [target as HTMLElement]))
          observer.unobserve(target)
        }
      })
    },
    { root: null, rootMargin: "50% 0px", threshold: 0 }
  )

  nodes.forEach(node => intersectionObserver.observe(node))
}

const loadPictureTags = (nodes: ImageNodes = defaultNodes()): void => {
  appendPictureTags(nodes)
}

export {
  loadBackgroundImages,
  loadPictureTags
}
