const BOTTOM = "bottom"
const SCROLL_UP_CLASS = "scrolling-up"
const SCROLL_DOWN_CLASS = "scrolling-down"
const IS_STICKY_CLASS = "is-sticky"
const anchorElement = document.getElementById(window.location.hash.substring(1))
const components = document.querySelectorAll("[data-hide-and-seek]") || []
const stickyMap = new WeakMap()

let lastScroll = 0
let ticking = false

const toggleStickyClass = () : void => {
  for (const component of components) {
    const isSticky = stickyMap.get(component)
    const rect = component.getBoundingClientRect()

    component.classList.toggle(IS_STICKY_CLASS, isSticky(rect))
  }
}

const toggleClasses = (): void => {
  ticking = false
  const currentScroll = window.scrollY
  const isAnchorScroll = anchorElement && anchorElement.offsetTop === currentScroll

  toggleStickyClass()

  if (isAnchorScroll) return

  const bodyClassList = document.body.classList

  if (currentScroll <= 0) {
    bodyClassList.remove(SCROLL_UP_CLASS)
    return
  }

  if (currentScroll > lastScroll) {
    bodyClassList.remove(SCROLL_UP_CLASS)
    bodyClassList.add(SCROLL_DOWN_CLASS)
  } else {
    bodyClassList.remove(SCROLL_DOWN_CLASS)
    bodyClassList.add(SCROLL_UP_CLASS)
  }

  lastScroll = currentScroll
}

for (const component of components) {
  const isBottomAnchored = (component as HTMLElement).dataset.hideAndSeek === BOTTOM

  stickyMap.set(
    component,
    isBottomAnchored
      ? (rect : DOMRect) : boolean => rect.bottom >= window.innerHeight
      : (rect : DOMRect) : boolean => rect.top <= 0
  )
}

window.addEventListener("scroll", () => {
  if (!ticking) requestAnimationFrame(toggleClasses)
  ticking = true
})
