import Fuse, { FuseSortFunctionArg } from "fuse.js"

interface AutocompleteItem extends Flags {
  name: string
}

declare global {
  interface AutocompleteData extends Flags {
    regions: AutocompleteItem[]
    venues: AutocompleteItem[]
  }
}

const REGION_OPTIONS = {
  threshold: 0,
  tokenize: true,
  keys: ["name"]
}

const VENUE_OPTIONS = {
  threshold: 0.4,
  keys: ["name"],
  sortFn: ((a : FuseSortFunctionArg, b : FuseSortFunctionArg) : number => {
    if (a.score === b.score) {
      return a.item.name > b.item.name ? 1 : -1
    } else {
      return a.score > b.score ? 1 : -1
    }
  })
}

const MAX = 6

export class FuzzySearch {
  private regionsFuse: Fuse<AutocompleteItem>
  private venuesFuse: Fuse<AutocompleteItem>

  constructor(readonly data: AutocompleteData) {
    this.regionsFuse = new Fuse(data.regions, REGION_OPTIONS)
    this.venuesFuse = new Fuse(data.venues, VENUE_OPTIONS)
  }

  search(text: string) : AutocompleteData {
    return {
      regions: this.regionsFuse.search(text).slice(0, MAX).map(result => result.item),
      venues: this.venuesFuse.search(text).slice(0, MAX).map(result => result.item)
    }
  }
}
